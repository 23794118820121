import React from "react";
import { silentAuth } from "./src/utils/auth";
import "./src/styles/global.css";

class SessionCheck extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isClient: false,
    };
  }

  handleCheckSession = () => {
    this.setState({ loading: false });
  };

  componentDidMount() {
    this.setState({ isClient: true });
    silentAuth(this.handleCheckSession);
  }

  // only returns the children when loading is false.
  render() {
    return (
      this.state.loading === false && (
        <React.Fragment key={this.state.isClient}>
          {this.props.children}
        </React.Fragment>
      )
    );
  }
}

export const wrapPageElement = ({ element }) => {
  return <SessionCheck>{element}</SessionCheck>;
};
