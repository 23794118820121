import { isBrowser } from "./auth";

/**
 * 
 * @param {*} event 
 * @param {*} obj 
 * @returns 
 */
export const pushToDataLayer = (event, obj) => {
  if (!isBrowser) return;

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event,
    ...obj
  })
};
