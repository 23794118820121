import auth0 from "auth0-js";
import { navigate } from "gatsby";
import { resendConfirmationEmail, status } from "../services";
import { pushToDataLayer } from "./dataLayer";

// taken from https://auth0.com/blog/securing-gatsby-with-auth0/

export const isBrowser = typeof window !== "undefined";

export const auth = isBrowser
  ? new auth0.WebAuth({
      domain: process.env.GATSBY_AUTH0_DOMAIN,
      clientID: process.env.GATSBY_AUTH0_CLIENTID,
      redirectUri: window && window.location.origin + "/callback",
      responseType: "token id_token",
      audience: process.env.GATSBY_AUTH0_AUDIENCE,
      scope: "openid profile email update:userprofile",
      realm: "Username-Password-Authentication",
    })
  : {};

const tokens = {
  accessToken: false,
  idToken: false,
  expiresAt: false,
};

let user = {};

// Dependency: Node.js crypto module
// https://nodejs.org/api/crypto.html#crypto_crypto

// function base64URLEncode(str) {
//   return str
//     .toString("base64")
//     .replace(/\+/g, "-")
//     .replace(/\//g, "_")
//     .replace(/=/g, "");
// }
// const verifier = base64URLEncode(crypto.randomBytes(32));

// function sha256(buffer) {
//     return crypto.createHash('sha256').update(buffer).digest();
// }

// const challenge = base64URLEncode(sha256(verifier));

const onRedirectCallback = (appState) => {
  const url = localStorage.getItem("redirectUrl");

  if (!isBrowser) return;

  if (!appState) {
    if (typeof url === "undefined" || url === null) {
      return navigate("/account/downloads");
    } else {
      return navigate(url);
    }
  }
  if (typeof appState === "string") {
    if (appState === "/callback/") {
      return navigate("/account/downloads", { replace: true });
    }
    return;
  }
  if (!appState.redirectPath) {
    return navigate("/account/downloads");
  }

  return navigate(appState.redirectPath);
};

export const isActivated = () => {
  if (!isBrowser) {
    return;
  }

  return localStorage.getItem("isActivated") === "true";
};

export const getLocalFastvueId = () => {
  if (!isBrowser) {
    return;
  }

  return localStorage.getItem("fastvueId");
};

export const isAuthenticated = () => {
  if (!isBrowser) {
    return;
  }

  var expiresAt = JSON.parse(localStorage.getItem("expires_at"));
  return new Date().getTime() < expiresAt;
};

export const login = async (values, path) => {
  if (!isBrowser) {
    return;
  }
  // auth.authorize();
  return new Promise((resolve, reject) => {
    auth.login(
      {
        username: values.email,
        password: values.password,
        appState: {
          redirectPath: path,
        },
      },
      (err, authResult) => {
        if (err) {
          reject(err);
        }
        if (authResult) {
          resolve(authResult);
        }
      }
    );
  });
};

export const signup = async (values) => {
  if (!isBrowser) {
    return;
  }
  // auth.authorize();
  return new Promise((resolve, reject) => {
    auth.signup(
      {
        email: values.email,
        password: values.password,
        connection: "Username-Password-Authentication",
      },
      (err, authResult) => {
        if (err) {
          reject(err);
        }
        if (authResult) {
          resolve(authResult);
        }
      }
    );
  });
};

/**
 * @description Reset user's password
 * @param {String} value User's email for password reset
 * @returns {Promise<Object>} Password change result or error object
 */
export function passwordReset(value) {
  return new Promise((resolve, reject) => {
    auth.changePassword(
      {
        email: value.email,
        connection: "Username-Password-Authentication",
        clientID: process.env.GATSBY_AUTH0_CLIENTID,
      },
      (err, result) => (err ? reject(err) : resolve(result))
    );
  });
}

/**
 * @description Change user's password
 * @returns {Promise<Object>} Password change result or error object
 */
export function changeToNewPassword(value, token) {
  return new Promise((resolve, reject) => {
    auth.changePassword(
      {
        password: value.password,
        token: token,
        connection: "Username-Password-Authentication",
      },
      (err, result) => (err ? reject(err) : resolve(result))
    );
  });
}

export const resendConfirmEmailCode = async (email, userId) => {
  const data = {
    user_id: userId,
  };

  if (!isBrowser) {
    return;
  }
  try {
    const result = resendConfirmationEmail(data);
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const saveActivatedStatus = (isActivated, fastvueId) => {
  localStorage.setItem("isActivated", isActivated);
  if (fastvueId) {
    localStorage.setItem("fastvueId", fastvueId);
  }
};

export const handleActivate = async (callback) => {
  try {
    const res = await status();
    const response = res.data;
    if (response.success === false) {
      saveActivatedStatus(false);
      navigate("/activate");
      return;
    } else {
      saveActivatedStatus(
        response.data.isActivated === true,
        response.data.fastvueId
      );
      callback();
      return;
    }
  } catch (err) {
    callback(err);
    return;
  }
};

export const setSession =
  (cb = () => {}) =>
  (err, authResult) => {
    // console.log("setSessionParams", {
    //   err: JSON.stringify(err),
    //   authResult: authResult,
    // });
    if (err !== null) {
      logout("/login");
      return;
    } else if (authResult && authResult.accessToken && authResult.idToken) {
      let expiresAt = authResult.expiresIn * 1000 + new Date().getTime();
      tokens.expiresAt = expiresAt;
      tokens.idToken = authResult.idToken;
      tokens.accessToken = authResult.accessToken;

      auth.client.userInfo(tokens.accessToken, (_err, userProfile) => {
        if (_err) return;

        user = userProfile;

        if (userProfile.sub) {
          const expires_at = window.localStorage.getItem("expires_at");
          if (expires_at === null) {
            const provider = userProfile.sub.substring(
              0,
              userProfile.sub.indexOf("|")
            );
            // Object to be passed into datalayer
            const dataLayerObject = {
              ...(provider === "google-oauth2" && { login_strategy: "google" }),
              ...(provider === "windowslive" && {
                login_strategy: "microsoft",
              }),
              ...(provider === "auth0" && {
                login_strategy: "username_password",
              }),
            };
            pushToDataLayer("user_login", dataLayerObject);
          }

          window.localStorage.setItem("expires_at", tokens.expiresAt);
          onRedirectCallback(authResult.appState);
        }
        cb();
        return;
      });
    } else {
      cb();
      return;
    }
  };

export const handleAuthentication = () => {
  if (!isBrowser) {
    return;
  }
  //console.log("handleAuthentication parseHash start");
  auth.parseHash(setSession());
};

export const getProfile = () => {
  return user;
};

// Auth0 provides a checkSession function that checks whether a user is logged in and, if so,
// returns valid tokens and user profile information for use in the application without requiring user interaction.
export const silentAuth = (callback) => {
  // console.log("isAuthenticated", isAuthenticated());
  if (!isAuthenticated()) {
    clearUserData();
    return callback();
  }
  // console.log("checkSessionStart");

  const callbackUrl = window.origin + "/callback"; //passing a whitelisted callback url to avoid a timeout error on checkSession when refreshing sub-routes
  auth.checkSession({ redirectUri: callbackUrl }, setSession(callback));
};

export const getAccessToken = () => {
  return tokens.accessToken;
};

export const getIdToken = () => {
  return tokens.idToken;
};

export const clearUserData = () => {
  tokens.accessToken = false;
  tokens.idToken = false;
  user = {};
  window.localStorage.removeItem("redirectUrl");
  window.localStorage.removeItem("isActivated");
  window.localStorage.removeItem("fastvueId");
  window.localStorage.removeItem("expires_at");
};
// For logging out the user from app.
export const logout = (returnPath) => {
  clearUserData();
  pushToDataLayer("user_logout");

  const returnPage = returnPath
    ? window.location.origin + returnPath
    : window.location.origin;

  auth.logout({
    returnTo: returnPage,
  });
};
