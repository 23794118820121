import { getAccessToken, isAuthenticated, logout } from "../utils/auth";
import apiClient from "./http-common";

// const headers = {
//   "Content-Type": "application/json",
//   "Access-Control-Request-Headers": "*",
// };

// Add a request interceptor
apiClient.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    // const auth = isAuthenticated();
    // if(!auth) return;
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
apiClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response) {
      if (error.response.status === 401) {
        // Do something, call logout function or refresh token.
        logout();
        // return a request
        return;
      }
    }
    return Promise.reject(error);
  }
);

// const url = process.env.GATSBY_SITE_URL;
// const url = window && window.location.host;

/* Activate API

--- Request ----
{
  "external_ids": {
    "salesforce_id": "fda",
    "fv_portal_id": "123",
    "auth0_ids": [
      "(google | asdfasdfasdfasdf)",
      "(user-password-database: asdfasdfasdf)",
      "(microsoft | asdfasdfasdf)"
    ]
  },
  "firstName": "Steve",
  "lastName": "Squid",
  "email": "steve-squid@fastvue.co",
  "organization": {
    "name": "Fastvue",
    "type": "Education",
    "industry": "Education",
    "size": 1500,
    "Country": "Australia"
  },
  "products": ["Reporter for Palo Alto", "Reporter for FortiGate"
  ]
}
--- Response ---
{
  "success": true,
  "data": {
    "insertedId": "62b089f17d3e509b76c926c4",
    "fastvueId": "62b089f17d3e509b76c926c4",
    "isActivated": true
  },
  "message": "User activated successfully"
}
*/
export default function activateUser(data) {
  return apiClient.post("/api/activate", data, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
}

/*  Login API

--- Request ---
{
   "email": "user@company.com",
   "auth_id": "asdfqwerty"
} 
--- Response ---
{
  "success": true,
  "data": {
    "isActivated": true,
    "fastvueId": "1234",
    "updateResult": {
      "matchedCount": 1,
      "modifiedCount": 1
    }
  },
  "message": "User information"
}
*/
export function login(email) {
  return apiClient.post("/api/login", email);
}

export function getUserById(fastvueId) {
  return apiClient.get("/api/user/" + fastvueId, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
}

export function status() {
  return apiClient.get("/api/status", {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
}

// function to call post pricing API using axios
export function postDummy(data) {
  return apiClient.post("https://jsonplaceholder.typicode.com/posts", data);
}

// function to call get pricing API using axios
export function getDummy() {
  return apiClient.get("https://jsonplaceholder.typicode.com/posts");
}

// function to call post API using axios
export function downloadLog(data) {
  return apiClient.post("/api/download", data, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
}

export function updateUserDetails(id, data) {
  return apiClient.patch(`/api/user/${id}`, data, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
}

// function to call post API using axios
export function submitForm(data, action) {
  const isAuth = isAuthenticated();
  return apiClient.post(`/api/submit/${action}`, data, {
    headers: {
      ...(isAuth && { Authorization: `Bearer ${getAccessToken()}` }),
    },
  });
}

export function resendConfirmationEmail(data) {
  return apiClient.post(`/api/verify-email`, data);
}
